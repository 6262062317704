import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="push-notifications-campaign-form"
export default class extends Controller {
  static targets = ["sendNowCheckbox", "scheduledAt", "patientIds", "patientsContainer", "target"]

  connect() {
    console.log(
      "Connected to push-notifications-campaign-form controller",
      this.sendNowCheckboxTarget,
      this.scheduledAtTarget
    )

    this.sendNowCheckboxChanged();
    this.targetChanged();
  }

  sendNowCheckboxChanged() {
    if (this.sendNowCheckboxTarget.checked) {
      this.scheduledAtTarget.disabled = true
      this.scheduledAtTarget.classList.add("d-none");
    } else {
      this.scheduledAtTarget.disabled = false
      this.scheduledAtTarget.classList.remove("d-none");
    }
  }

  targetChanged() {
    console.log("Target changed", this.targetTarget.value)

    if (this.targetTarget.value === "selected_patients") {
      this.patientsContainerTarget.classList.remove("d-none");
      this.patientIdsTarget.setAttribute("required", "required");
    } else {
      this.patientsContainerTarget.classList.add("d-none");
      this.patientIdsTarget.removeAttribute("required");
    }
  }
}
