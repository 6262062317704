import {Controller} from "@hotwired/stimulus";
import SignaturePad from "signature_pad";

/**
 * Sprinkles for Media Item Form
 */
export default class MassageSignFormController extends Controller {
  static targets = [];

  /**
   *
   * @returns {HTMLCanvasElement}
   */
  get canvas() {
    return this.element.querySelector("canvas");
  }

  /**
   * @return {HTMLFormElement}
   */
  get form() {
    return this.element;
  }

  clear() {
    this.pad.clear();
    this.saveButton.setAttribute("disabled", "disabled");
  }

  connect() {
    console.log("Hello, form", this.canvas);
    this.pad = new SignaturePad(this.canvas, {
      onEnd: () => {
        this.saveBlob();
        this.saveButton.removeAttribute("disabled");
      },
    });

    // window.addEventListener("resize", this.resizeCanvas.bind(this));
    // this.resizeCanvas();
    this.initEvents();
    this.saveButton.setAttribute("disabled", "disabled");
  }

  get saveButton() {
    return this.form.querySelector("button[type=submit]");
  }

  saveBlob() {
    this.canvas.toBlob((blob) => {
      this.blob = blob;
    }, "image/png");
  }

  initEvents() {
    this.beforeRequestHandler = this.onBeforeFetchRequest.bind(this);
    window.addEventListener(
      "turbo:before-fetch-request",
      this.beforeRequestHandler
    );
  }

  disconnect() {
    console.log("=> SIGN FORM: BYE");
    window.removeEventListener(
      "turbo:before-fetch-request",
      this.beforeRequestHandler
    );
  }

  onBeforeFetchRequest(event) {
    console.log("=> FORM: Before fetch request", event.detail);

    if (
      event.detail.fetchOptions.method == "GET" ||
      event.detail.fetchOptions.body == null
    ) {
      return;
    }

    // @type {FormData}
    const formData = event.detail.fetchOptions.body;
    formData.set("sessions_massage[patient_sign]", this.blob);
  }
}
