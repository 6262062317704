import { Controller } from "@hotwired/stimulus"

/**
 *
 * This controller is used to print content of a URL
 * it uses a iframe to load the content and print it
 * Connects to data-controller="external-print"
 * @example
 *  <button data-controller="external-print"
 *         data-external-print-url-value="<%= admin_assistance_print_path(@patient) %>"
 *         data-action="click->external-print#print"
 *         type="button">
 *    <i class="bi bi-printer"></i>
 *    IMPRIMIR
 *  </button>
 */
export default class extends Controller {
  static values = { url: String }
  initialize() {
  }

  print() {
    const hideFrame = document.createElement("iframe");
    const button = this.element;

    hideFrame.onload = () => {
      const closePrint = () => {
        document.body.removeChild(hideFrame);
      };
      hideFrame.contentWindow.onbeforeunload = closePrint;
      hideFrame.contentWindow.onafterprint = closePrint;
      hideFrame.contentWindow.print();
      button.removeAttribute("disabled");
    };
    hideFrame.style.display = "none"; // hide iframe
    hideFrame.src = this.urlValue;
    button.setAttribute("disabled", "disabled");

    document.body.appendChild(hideFrame);
  }
}
